<template>
  <ValidationProvider
    :rules="validationRules"
    class="question latlong column is-half"
    tag="div"
    v-slot="{ errors, dirty, valid }"
  >
    <div class="question-text">
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
    </div>
    <div class="question-content card">
      <div class="card-content">
        <b-field
          :type="{ 'is-danger': errors[0], 'is-success': dirty && valid }"
        >
          <b-taginput
            v-model="tags"
            :data="addresses"
            autocomplete
            field="name"
            open-on-focus
            attached
            :loading="isFetching"
            :maxtags="`${qOpts.max}`"
            :has-counter="false"
            icon="map-marker"
            :placeholder="$t('lat_lng.placeholder')"
            :create-tag="convertLatLng"
            :before-adding="validateAddress"
            @add="onAdd"
            @remove="onRemove"
            @typing="searchAddress"
          >
            <template slot-scope="props">
              <b-icon size="is-small" icon="map-marker"> </b-icon>
              {{ props.option.description }}
            </template>
            <template #empty>
              {{ $t("lat_lng.empty_address") }}
            </template>
            <template #footer>
              <span class="is-pulled-right">
                <img
                  src="/powered_by_google_on_white.png"
                  alt="Powered by Google"
                />
              </span>
            </template>
          </b-taginput>
        </b-field>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
import { QuestionMixin } from "@/mixins/question";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import { placeDetails, placeSearch } from "@/api/google";
// only uncomment in development ENV
// import { placeDetails, placeSearch } from "@/api/google.local";

export default {
  name: "LatLong",
  mixins: [QuestionMixin],
  data() {
    return {
      addresses: [],
      tags: [],
      isFetching: false,
      location: {},
      countries: "country:jp",
      offsetTop: 0,
    };
  },
  computed: {
    currentValue() {
      return this.value[this.question.qid];
    },
  },
  created() {
    this.handlerParamComponents();
  },
  watch: {
    currentValue(newVal, oldVal) {
      if (oldVal === undefined && !isEmpty(newVal)) {
        this.tags = newVal;
      }
    },
  },
  methods: {
    onRemove() {
      this.$emit("input", { [this.question.qid]: this.tags });
    },
    async onAdd(address) {
      await placeDetails({
        place_id: address.place_id,
      })
        .then((response) => {
          const { data } = response;
          if (data.status !== "OK") throw new Error("Invalid status");
          this.tags = this.tags.map((x) =>
            x.id === address.id
              ? {
                  ...{ location: data.results[0]?.geometry?.location || {} },
                  ...x,
                }
              : x
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$emit("input", { [this.question.qid]: this.tags });
        });
    },
    convertLatLng(address) {
      const { place_id, description } = address;
      return {
        placeId: place_id,
        name: description,
      };
    },

    validateAddress(address) {
      const isDuplicated = this.tags.some(
        (el) => el.placeId === address.place_id
      );
      return !isDuplicated;
    },

    searchAddress: debounce(function (text) {
      if (!text) {
        return;
      }
      this.isFetching = true;

      const elmSurvey = document.getElementById("survey");
      if (elmSurvey) {
        var chatHistory = document.getElementById("chats");
        const itv = setInterval(() => {
          chatHistory.scrollTo({
            top: chatHistory.scrollHeight + 500,
            behavior: "smooth",
          });
          clearInterval(itv);
        }, 1000);
        elmSurvey.animate([{ height: "350px", opacity: 1 }], {
          duration: 300,
          fill: "both",
        });
      }

      placeSearch({
        input: text,
        language: this.$i18n.locale,
        components: this.countries,
      })
        .then((response) => {
          this.addresses = response.data.predictions;
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 1000),

    handlerParamComponents() {
      const countryOptions =
        this.question.options?.country_selected?.config || [];
      if (countryOptions.length > 0) {
        this.countries = countryOptions
          .map((country) => "country:" + country.iso2)
          .join("|");
      }
    },
  },
  mounted() {
    this.$emit("mounted", "is-half");
  },
};
</script>

<style lang="scss"></style>
